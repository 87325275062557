import { ScheduleLabLink } from 'components/links/scheduleLabLink';
import { SupportEmailLink } from 'components/links/supportEmailLink';
import { StandardLayout } from 'layouts/standardLayout/standardLayout';
import * as React from 'react';
import { Helmet } from 'react-helmet';
import * as pageClasses from 'scss/legalDocuments.module.scss';

const PrivacyPage: React.FC = () => {
  return (
    <main>
      <Helmet htmlAttributes={{ lang: 'en' }}>
        <title>ScheduleLab - Privacy Policy</title>
        <meta charSet="utf-8" />
      </Helmet>

      <StandardLayout title="Privacy Policy">
        <article className={pageClasses.container}>
          <section>
            <p>
              <em>Last Updated: May 1, 2021</em>
            </p>
            <p>
              <em>Effective Date: May 1, 2021</em>
            </p>
          </section>
          <section>
            <h2>Introduction</h2>
            <p>
              This Privacy Policy describes the policies of ScheduleLab, LLC, on the collection, use and disclosure of
              your information that we collect when you use our app ScheduleLab for Colleges (the “Service” -{' '}
              <ScheduleLabLink />
              ). By accessing or using the Service, you are consenting to the collection, use and disclosure of your
              information in accordance with this Privacy Policy. If you do not consent to the same, please do not
              access or use the Service.
            </p>
            <p>
              We may modify this Privacy Policy at any time without any prior notice to you and will post the revised
              Privacy Policy on the Service & associated website. The revised Policy will be effective 180 days from
              when the revised Policy is posted in the Service and your continued access or use of the Service after
              such time will constitute your acceptance of the revised Privacy Policy. We therefore recommend that you
              periodically review this page.
            </p>
            <p>If you have any questions, you may reach out to ScheduleLab, LLC via the following channels:</p>
            <p>
              <strong>Address</strong>: 200 Bedford Rd Apt 24B Woburn MA 01801, United States of America
            </p>
            <p>
              <strong>Email</strong>: <SupportEmailLink />
            </p>
          </section>
          <section>
            <h2>Information Collected</h2>
            <p>
              The following is a general outline of the data we currently collect from users to operate the Service.
            </p>
            <ul>
              <li>College requests (may include email if desired)</li>
              <li>Selected college in app</li>
              <li>Crash reports</li>
            </ul>
          </section>
          <section>
            <h2>How We Use Your Information</h2>
            <p>We will use the information that we collect about you for the following purposes:</p>
            <ul>
              <li>Supporting new colleges</li>
              <li>Bug resolution</li>
              <li>Support</li>
            </ul>
            <p>
              If we want to use your information for any other purpose, we will ask you for consent and will use your
              information only on receiving your consent and then, only for the purpose(s) for which grant consent
              unless we are required to do otherwise by law.
            </p>
          </section>
          <section>
            <h2>Your Rights</h2>
            <p>
              Depending on the law that applies, you may have a right to access and rectify or erase your personal data
              or receive a copy of your personal data, restrict or object to the active processing of your data, ask us
              to share (port) your personal information to another entity, withdraw any consent you provided to us to
              process your data, a right to lodge a complaint with a statutory authority and such other rights as may be
              relevant under applicable laws. To exercise these rights, you can write to us at <SupportEmailLink />. We
              will respond to your request in accordance with applicable law.
            </p>
            <p>
              Do note that if you do not allow us to collect or process the required personal information or withdraw
              the consent to process the same for the required purposes, you may not be able to access or use the
              services for which your information was sought.
            </p>
          </section>
          <section>
            <h2>Security</h2>
            <p>
              The security of your information is important to us and we will use reasonable security measures to
              prevent the loss, misuse or unauthorized alteration of your information under our control. However, given
              the inherent risks, we cannot guarantee absolute security and consequently, we cannot ensure or warrant
              the security of any information you transmit to us and you do so at your own risk.
            </p>
          </section>
          <section>
            <h2>Grievance / Data Protection Officer</h2>
            <p>
              If you have any queries or concerns about the processing of your information that is available with us,
              you may email our staff at <SupportEmailLink /> or by contacting us at our mailing address. We will
              address your concerns in accordance with applicable law.
            </p>
          </section>
        </article>
      </StandardLayout>
    </main>
  );
};

export default PrivacyPage;
