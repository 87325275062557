import { BubbleVisual } from '@schedulelab/components/bubbleVisual/bubbleVisual';
import { Footer } from 'components/footer/footer';
import { NavigationBar } from 'components/navigationBar/navigationBar';
import * as layoutClasses from 'layouts/standardLayout/standardLayout.module.scss';
import React, { PropsWithChildren } from 'react';

type StandardLayoutProps = {
  title: string;
};

export const StandardLayout: React.FC<PropsWithChildren<StandardLayoutProps>> = (props) => {
  const { title } = props;
  return (
    <>
      <NavigationBar />
      <div className={layoutClasses.pageLayout}>
        <article className={layoutClasses.pageContent}>
          <section className={layoutClasses.innerContent}>
            <BubbleVisual bubbleCount={25}>
              <div className={layoutClasses.headerContainer}>
                <h1 className={layoutClasses.headerText}>{title}</h1>
              </div>
            </BubbleVisual>
            {props.children}
          </section>
        </article>
        <Footer />
      </div>
    </>
  );
};
