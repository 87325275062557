import * as componentClasses from 'components/standardLink/standardLink.module.scss';
import { GatsbyLinkProps, Link } from 'gatsby';
import React from 'react';

type StandardLinkProps = Omit<GatsbyLinkProps<unknown>, 'ref'>;

export const StandardLink: React.FC<StandardLinkProps> = (props) => {
  if (props.to.startsWith('/')) {
    return (
      <Link className={componentClasses.link} {...props}>
        {props.children}
      </Link>
    );
  }
  return (
    <a className={componentClasses.link} href={props.to} {...props}>
      {props.children}
    </a>
  );
};
